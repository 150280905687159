import React from 'react';
import { IconButton } from '@mui/material';
import { ExitToApp } from '@mui/icons-material';
import '../Css/Header.css';

function Header({ name, handleLogout }) {
  return (
    <header className="directory-header">
      <div className="user-info" data-aos="fade-right" data-aos-delay="100">
        <span>Bienvenido {name}</span>
        <div className="logout-container" data-aos="fade-left" data-aos-delay="200">
          <IconButton onClick={handleLogout} style={{ color: 'white' }} className="logout-button">
            <ExitToApp />
          </IconButton>
        </div>
      </div>
    </header>
  );
}

export default Header;