import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";
import { db } from "./firebase";
import "./Css/FormularioPAT.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { collection, addDoc, doc, getDoc } from "firebase/firestore";
import Header from "./Components/Header.js";
import {
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Snackbar,
  Alert,
  TextField,
  Select,
  MenuItem,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Dialog,
  Button,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import logoIzquierda from "./Images/LogoAyuntamiento.png";
import logoDerecha from "./Images/logo_impl.png";

function FormularioPAT() {
  const [name, setName] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const { dependencia, docPath, ejeRectorPMD, lineaAccionPMD, programaPMD } =
    location.state;

  const [formValues, setFormValues] = useState({
    ejeRectorPMD: ejeRectorPMD || "",
    lineaAccionPMD: lineaAccionPMD || "",
    programaPMD: programaPMD || "",
  });

  const [formData, setFormData] = useState({
    ejeRectorPMD: ejeRectorPMD || "",
    lineaAccionPMD: lineaAccionPMD || "",
    programaPMD: programaPMD || "",
    ejeGeneralPND: "",
    ejeTransversalPND: "",
    ejeRectorPDE: "",
    ejeGeneralPDE: "",
    programaPDE: "",
    ejeTransversalPDE: "",
    programaEjePDE: "",
    finalidadCF: "",
    funcionCF: "",
    subfuncionCF: "",
    actividades: [],
    responsableElaboracion: "",
    titularDependencia: "",
    userName: "",
  });

  const [ejeGeneralOptions, setEjeGeneralOptions] = useState([]);
  const [ejeTransversalOptions, setEjeTransversalOptions] = useState([]);
  const [ejeRectorOptions, setEjeRectorOptions] = useState([]);
  const [ejeGeneralPDEOptions, setEjeGeneralPDEOptions] = useState([]);
  const [filteredEjeGeneralPDEOptions, setFilteredEjeGeneralPDEOptions] = useState([]);
  const [programaPDEOptions, setProgramaPDEOptions] = useState([]);
  const [filteredProgramaPDEOptions, setFilteredProgramaPDEOptions] = useState([]);
  const [ejeTransversalPDEOptions, setEjeTransversalPDEOptions] = useState([]);
  const [ejeTransversalProgramaPDEOptions, setEjeTransversalProgramaPDEOptions,] = useState([]);
  const [filteredejeTransversalProgramaPDEOptions, setFilteredEjeTransversalProgramaPDEOptions,] = useState([]);
  const [finalidadCFOptions, setFinalidadCFOptions] = useState([]);
  const [funcionCFOptions, setFuncionCFOptions] = useState([]);
  const [filteredFuncionCFOptions, setFilteredFuncionCFOptions] = useState([]);
  const [subfuncionCFOptions, setSubfuncionCFOptions] = useState([]);
  const [filteredSubfuncionCFOptions, setFilteredSubfuncionCFOptions] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [nombrePAT, setNombrePAT] = useState("");

  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
    const storedName = localStorage.getItem("userName");
    if (storedName) {
      setName(storedName);
      setFormData((prevData) => ({
        ...prevData,
        userName: storedName,
      }));
    } else {
      navigate("/login");
    }

    setFormValues((prevValues) => ({
      ...prevValues,
      ejeRectorPMD: ejeRectorPMD || "",
      lineaAccionPMD: lineaAccionPMD || "",
      programaPMD: programaPMD || "",
    }));

    setFormData((prevData) => ({
      ...prevData,
      ejeRectorPMD: ejeRectorPMD || "",
      lineaAccionPMD: lineaAccionPMD || "",
      programaPMD: programaPMD || "",
    }));

    const fetchData = async () => {
      try {
        const docRefPND = doc(db, "VinculacionPND", "PlanNacionalDesarrollo");
        const docSnapPND = await getDoc(docRefPND);
        if (docSnapPND.exists()) {
          const data = docSnapPND.data();
          setEjeGeneralOptions(data.EjeGeneral || []);
          setEjeTransversalOptions(data.EjeTransversal || []);
        } else {
          console.log("No such document!");
        }

        const docRefPDE = doc(db, "VinculacionPED", "PlanEstatalDesarrollo");
        const docSnapPDE = await getDoc(docRefPDE);
        if (docSnapPDE.exists()) {
          const data = docSnapPDE.data();
          setEjeRectorOptions(data.EjeRector || []);
          setEjeGeneralPDEOptions(data.EjeGeneral || []);
          setProgramaPDEOptions(data.Programa || []);
          setEjeTransversalPDEOptions(data.EjeTransversal || []);
          setEjeTransversalProgramaPDEOptions(
            data.EjeTransversalPrograma || []
          );
        } else {
          console.log("No such document!");
        }

        const docRefCF = doc(db, "ClasificacionFuncional", "Clasificador");
        const docSnapCF = await getDoc(docRefCF);
        if (docSnapCF.exists()) {
          const data = docSnapCF.data();
          setFinalidadCFOptions(data.Finalidad || []);
          setFuncionCFOptions(data.Funcion || []);
          setSubfuncionCFOptions(data.Subfuncion || []);
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error getting document: ", error);
      }
    };

    fetchData();
  }, [
    navigate,
    dependencia,
    docPath,
    ejeRectorPMD,
    lineaAccionPMD,
    programaPMD,
  ]);

  const handleLogout = () => {
    localStorage.removeItem("userName");
    navigate("/login");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    if (name === "ejeRectorPDE") {
      let filteredOptions = [];
      switch (value) {
        case ejeRectorOptions[0]:
          filteredOptions = ejeGeneralPDEOptions.slice(0, 2);
          break;
        case ejeRectorOptions[1]:
          filteredOptions = ejeGeneralPDEOptions.slice(2, 6);
          break;
        case ejeRectorOptions[2]:
          filteredOptions = ejeGeneralPDEOptions.slice(6, 9);
          break;
        case ejeRectorOptions[3]:
          filteredOptions = ejeGeneralPDEOptions.slice(9, 12);
          break;
        default:
          filteredOptions = ejeGeneralPDEOptions;
          break;
      }
      setFilteredEjeGeneralPDEOptions(filteredOptions);
    } else if (name === "ejeGeneralPDE") {
      let filteredOptions = [];
      switch (value) {
        case ejeGeneralPDEOptions[0]:
          filteredOptions = programaPDEOptions.slice(0, 6);
          break;
        case ejeGeneralPDEOptions[1]:
          filteredOptions = programaPDEOptions.slice(6, 14);
          break;
        case ejeGeneralPDEOptions[2]:
          filteredOptions = programaPDEOptions.slice(14, 18);
          break;
        case ejeGeneralPDEOptions[3]:
          filteredOptions = programaPDEOptions.slice(18, 19);
          break;
        case ejeGeneralPDEOptions[4]:
          filteredOptions = programaPDEOptions.slice(19, 20);
          break;
        case ejeGeneralPDEOptions[5]:
          filteredOptions = programaPDEOptions.slice(20, 23);
          break;
        case ejeGeneralPDEOptions[6]:
          filteredOptions = programaPDEOptions.slice(23, 26);
          break;
        case ejeGeneralPDEOptions[7]:
          filteredOptions = programaPDEOptions.slice(26, 27);
          break;
        case ejeGeneralPDEOptions[8]:
          filteredOptions = programaPDEOptions.slice(27, 29);
          break;
        case ejeGeneralPDEOptions[9]:
          filteredOptions = programaPDEOptions.slice(29, 33);
          break;
        case ejeGeneralPDEOptions[10]:
          filteredOptions = programaPDEOptions.slice(33, 35);
          break;
        case ejeGeneralPDEOptions[11]:
          filteredOptions = programaPDEOptions.slice(35, 37);
          break;
        default:
          filteredOptions = programaPDEOptions;
          break;
      }
      setFilteredProgramaPDEOptions(filteredOptions);
    }
    if (name === "ejeTransversalPDE") {
      let filteredOptions = [];
      switch (value) {
        case ejeTransversalPDEOptions[0]:
          filteredOptions = ejeTransversalProgramaPDEOptions.slice(0, 1);
          break;
        case ejeTransversalPDEOptions[1]:
          filteredOptions = ejeTransversalProgramaPDEOptions.slice(1, 2);
          break;
        case ejeTransversalPDEOptions[2]:
          filteredOptions = ejeTransversalProgramaPDEOptions.slice(2, 5);
          break;
        default:
          filteredOptions = ejeTransversalProgramaPDEOptions;
          break;
      }
      setFilteredEjeTransversalProgramaPDEOptions(filteredOptions);
    }
    if (name === "finalidadCF") {
      let filteredOptions = [];
      switch (value) {
        case finalidadCFOptions[0]:
          filteredOptions = funcionCFOptions.slice(0, 8);
          break;
        case finalidadCFOptions[1]:
          filteredOptions = funcionCFOptions.slice(8, 15);
          break;
        case finalidadCFOptions[2]:
          filteredOptions = funcionCFOptions.slice(15, 24);
          break;
        case finalidadCFOptions[3]:
          filteredOptions = funcionCFOptions.slice(24, 28);
          break;
        default:
          filteredOptions = funcionCFOptions;
          break;
      }
      setFilteredFuncionCFOptions(filteredOptions);
    } else if (name === "funcionCF") {
      let filteredOptions = [];
      switch (value) {
        case funcionCFOptions[0]:
          filteredOptions = subfuncionCFOptions.slice(0, 2);
          break;
        case funcionCFOptions[1]:
          filteredOptions = subfuncionCFOptions.slice(2, 6);
          break;
        case funcionCFOptions[2]:
          filteredOptions = subfuncionCFOptions.slice(6, 15);
          break;
        case funcionCFOptions[3]:
          filteredOptions = subfuncionCFOptions.slice(15, 16);
          break;
        case funcionCFOptions[4]:
          filteredOptions = subfuncionCFOptions.slice(16, 18);
          break;
        case funcionCFOptions[5]:
          filteredOptions = subfuncionCFOptions.slice(18, 21);
          break;
        case funcionCFOptions[6]:
          filteredOptions = subfuncionCFOptions.slice(21, 25);
          break;
        case funcionCFOptions[7]:
          filteredOptions = subfuncionCFOptions.slice(25, 30);
          break;
        case funcionCFOptions[8]:
          filteredOptions = subfuncionCFOptions.slice(30, 36);
          break;
        case funcionCFOptions[9]:
          filteredOptions = subfuncionCFOptions.slice(36, 43);
          break;
        case funcionCFOptions[10]:
          filteredOptions = subfuncionCFOptions.slice(43, 48);
          break;
        case funcionCFOptions[11]:
          filteredOptions = subfuncionCFOptions.slice(48, 52);
          break;
        case funcionCFOptions[12]:
          filteredOptions = subfuncionCFOptions.slice(52, 58);
          break;
        case funcionCFOptions[13]:
          filteredOptions = subfuncionCFOptions.slice(58, 67);
          break;
        case funcionCFOptions[14]:
          filteredOptions = subfuncionCFOptions.slice(67, 68);
          break;
        case funcionCFOptions[15]:
          filteredOptions = subfuncionCFOptions.slice(68, 70);
          break;
        case funcionCFOptions[16]:
          filteredOptions = subfuncionCFOptions.slice(70, 76);
          break;
        case funcionCFOptions[17]:
          filteredOptions = subfuncionCFOptions.slice(76, 82);
          break;
        case funcionCFOptions[18]:
          filteredOptions = subfuncionCFOptions.slice(82, 85);
          break;
        case funcionCFOptions[19]:
          filteredOptions = subfuncionCFOptions.slice(85, 91);
          break;
        case funcionCFOptions[20]:
          filteredOptions = subfuncionCFOptions.slice(91, 92);
          break;
        case funcionCFOptions[21]:
          filteredOptions = subfuncionCFOptions.slice(92, 94);
          break;
        case funcionCFOptions[22]:
          filteredOptions = subfuncionCFOptions.slice(94, 98);
          break;
        case funcionCFOptions[23]:
          filteredOptions = subfuncionCFOptions.slice(98, 101);
          break;
        case funcionCFOptions[24]:
          filteredOptions = subfuncionCFOptions.slice(101, 103);
          break;
        case funcionCFOptions[25]:
          filteredOptions = subfuncionCFOptions.slice(103, 106);
          break;
        case funcionCFOptions[26]:
          filteredOptions = subfuncionCFOptions.slice(106, 110);
          break;
        case funcionCFOptions[27]:
          filteredOptions = subfuncionCFOptions.slice(110, 111);
          break;
        default:
          filteredOptions = subfuncionCFOptions;
          break;
      }
      setFilteredSubfuncionCFOptions(filteredOptions);
    }
  };

  const handleSubmit = async (e) => {
    setConfirmDialogOpen(false);
    e.preventDefault();
    try {
      const patCollectionRef = collection(db, `${docPath}/Pat`);
      await addDoc(patCollectionRef, { ...formData, nombrePAT })
      setSnackbarMessage("¡Plan Anual de Trabajo (PAT) guardado exitosamente!");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
      setTimeout(() => {
        navigate("/directory");
      }, 3200);
    } catch (error) {
      console.error("Error guardando los datos: ", error);
      setSnackbarMessage("Error guardando los datos");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleBackClick = () => {
    navigate("/directory");
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const agregarActividad = () => {
    setFormData((prevData) => ({
      ...prevData,
      actividades: [
        ...prevData.actividades,
        {
          descripcion: "",
          fundamentacionJuridica: "",
          metaAnual: "",
          unidadMedida: "",
          originalAnual: "",
          calendario: {
            primerTrimestre: "",
            segundoTrimestre: "",
            tercerTrimestre: "",
            cuartoTrimestre: "",
          },
          realizado: {
            primerTrimestre: "",
            segundoTrimestre: "",
            tercerTrimestre: "",
            cuartoTrimestre: "",
          },
          totalAcumulado: "",
          avanceFisicoVsOriginal: "",
          objetivoActividad: "",
          observaciones: "",
        },
      ],
    }));
  };

  const eliminarActividad = (index) => {
    setFormData((prevData) => {
      const nuevasActividades = [...prevData.actividades];
      nuevasActividades.splice(index, 1);
      return {
        ...prevData,
        actividades: nuevasActividades,
      };
    });
  };

  const handleActividadChange = (e, index) => {
    const { name, value } = e.target;
    setFormData((prevData) => {
      const nuevasActividades = [...prevData.actividades];
      nuevasActividades[index] = {
        ...nuevasActividades[index],
        [name]: value,
      };
      return {
        ...prevData,
        actividades: nuevasActividades,
      };
    });
  };

  const handleCalendarioChange = (e, index, trimestre) => {
    const { value } = e.target;
    setFormData((prevData) => {
      const nuevasActividades = [...prevData.actividades];
      nuevasActividades[index].calendario = {
        ...nuevasActividades[index].calendario,
        [trimestre]: value,
      };
      return {
        ...prevData,
        actividades: nuevasActividades,
      };
    });
  };

  const handleRealizadoChange = (e, index, trimestre) => {
    const { value } = e.target;
    setFormData((prevData) => {
      const nuevasActividades = [...prevData.actividades];
      nuevasActividades[index].realizado = {
        ...nuevasActividades[index].realizado,
        [trimestre]: value,
      };
      return {
        ...prevData,
        actividades: nuevasActividades,
      };
    });
  };

  const handleConfirmDialogOpen = () => {
    setConfirmDialogOpen(true);
  };

  const handleConfirmDialogClose = () => {
    setConfirmDialogOpen(false);
  };

  const handleNombrePATChange = (e) => {
    setNombrePAT(e.target.value);
  };

  return (
    <div className="formulario-pat-container">
      <Header name={name} handleLogout={handleLogout} />
      <img
        src={logoIzquierda}
        alt="Logo Izquierdo"
        className="logo-izquierda"
        data-aos="fade-up"
      />
      <img
        src={logoDerecha}
        alt="Logo Derecho"
        className="logo-derecha"
        data-aos="fade-up"
      />
      <div className="formulario-pat-back-button-container">
        <IconButton onClick={handleBackClick} data-aos="fade-up">
          <ArrowBack />
        </IconButton>
      </div>
      <div className="formulario-pat-title" data-aos="zoom-in-up">
        <h1>XLII AYUNTAMIENTO DE XALISCO</h1>
        <h1>Programa Anual de Trabajo</h1>
        <h3>DEPENDENCIA XLII AYUNTAMIENTO DE XALISCO</h3>
        <h3>UNIDAD RESPONSABLE {dependencia.toUpperCase()}</h3>
      </div>
      <form
        onSubmit={handleSubmit}
        className="formulario-pat-form"
        data-aos="fade-up"
        delay="1000"
      >
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>
              Vinculación con el Plan Municipal de Desarrollo 2021-2024
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="columna">
              <label>EJE RECTOR:</label>
              <TextField
                fullWidth
                name="ejeRectorPMD"
                value={formValues.ejeRectorPMD}
                onChange={handleChange}
                InputProps={{
                  readOnly: true,
                }}
              />
              <label>LÍNEA DE ACCIÓN:</label>
              <TextField
                fullWidth
                name="lineaAccionPMD"
                value={formValues.lineaAccionPMD}
                onChange={handleChange}
                InputProps={{
                  readOnly: true,
                }}
              />
              <label>PROGRAMA:</label>
              <TextField
                fullWidth
                name="programaPMD"
                value={formValues.programaPMD}
                onChange={handleChange}
                InputProps={{
                  readOnly: true,
                }}
              />
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>
              Vinculación con el Plan Nacional de Desarrollo 2019-2024
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="columna">
              <label>EJE GENERAL:</label>
              <Select
                name="ejeGeneralPND"
                value={formData.ejeGeneralPND}
                onChange={handleChange}
                fullWidth
                required
              >
                {ejeGeneralOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
              <label>EJE TRANSVERSAL:</label>
              <Select
                name="ejeTransversalPND"
                value={formData.ejeTransversalPND}
                onChange={handleChange}
                fullWidth
                required
              >
                {ejeTransversalOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>
              Vinculación con el Plan Estatal de Desarrollo 2021-2027
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="columna">
              <label>EJE RECTOR:</label>
              <Select
                name="ejeRectorPDE"
                value={formData.ejeRectorPDE}
                onChange={handleChange}
                fullWidth
                required
              >
                {ejeRectorOptions.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
              <label>EJE GENERAL:</label>
              <Select
                name="ejeGeneralPDE"
                value={formData.ejeGeneralPDE}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                required
              >
                {filteredEjeGeneralPDEOptions.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
              <label>PROGRAMA:</label>
              <Select
                name="programaPDE"
                value={formData.programaPDE}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                required
              >
                {filteredProgramaPDEOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
              <label>EJE TRANSVERSAL:</label>
              <Select
                name="ejeTransversalPDE"
                value={formData.ejeTransversalPDE}
                onChange={handleChange}
                fullWidth
                required
              >
                {ejeTransversalPDEOptions.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
              <label>PROGRAMA DEL EJE:</label>
              <Select
                name="programaEjePDE"
                value={formData.programaEjePDE}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                required
              >
                {filteredejeTransversalProgramaPDEOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Clasificación Funcional</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="columna">
              <label>FINALIDAD:</label>
              <Select
                name="finalidadCF"
                value={formData.finalidadCF}
                onChange={handleChange}
                fullWidth
                required
              >
                {finalidadCFOptions.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
              <label>FUNCIÓN:</label>
              <Select
                name="funcionCF"
                value={formData.funcionCF}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                required
              >
                {filteredFuncionCFOptions.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
              <label>SUBFUNCIÓN:</label>
              <Select
                name="subfuncionCF"
                value={formData.subfuncionCF}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                required
              >
                {filteredSubfuncionCFOptions.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Programa Anual de Trabajo (Actividades)</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="columna">
              {formData.actividades.map((actividad, index) => (
                <Accordion key={index}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Actividad No. {index + 1}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="columna">
                      <label>DESCRIPCIÓN:</label>
                      <textarea
                        name="descripcion"
                        value={actividad.descripcion}
                        onChange={(e) => handleActividadChange(e, index)}
                        required
                      />
                      <label>FUNDAMENTACIÓN JURÍDICA:</label>
                      <textarea
                        name="fundamentacionJuridica"
                        value={actividad.fundamentacionJuridica}
                        onChange={(e) => handleActividadChange(e, index)}
                        required
                      />
                      <label>META ANUAL:</label>
                      <textarea
                        name="metaAnual"
                        value={actividad.metaAnual}
                        onChange={(e) => handleActividadChange(e, index)}
                        onInput={(e) => {
                          const value = e.target.value;
                          if (!/^\d{0,3}$/.test(value)) {
                            e.target.value = value.slice(0, -1);
                          }
                        }}
                        required
                        maxLength="3"
                      />
                      <label>UNIDAD DE MEDIDA:</label>
                      <textarea
                        name="unidadMedida"
                        value={actividad.unidadMedida}
                        onChange={(e) => handleActividadChange(e, index)}
                        required
                      />
                      <label>ORIGINAL ANUAL:</label>
                      <textarea
                        name="originalAnual"
                        value={actividad.originalAnual}
                        onChange={(e) => handleActividadChange(e, index)}
                        onInput={(e) => {
                          const value = e.target.value;
                          if (!/^\d{0,3}$/.test(value)) {
                            e.target.value = value.slice(0, -1);
                          }
                        }}
                        required
                        maxLength="3"
                      />
                      <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <Typography>CALENDARIO</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="trimestre-container">
                            <label>1er. TRIMESTRE:</label>
                            <textarea
                              name={`calendario.primerTrimestre.${index}`}
                              value={actividad.calendario.primerTrimestre}
                              onChange={(e) =>
                                handleCalendarioChange(
                                  e,
                                  index,
                                  "primerTrimestre"
                                )
                              }
                              onInput={(e) => {
                                const value = e.target.value;
                                if (!/^\d{0,3}$/.test(value)) {
                                  e.target.value = value.slice(0, -1);
                                }
                              }}
                              maxLength="3"
                              required
                            />
                            <label>2do. TRIMESTRE:</label>
                            <textarea
                              name={`calendario.segundoTrimestre.${index}`}
                              value={actividad.calendario.segundoTrimestre}
                              onChange={(e) =>
                                handleCalendarioChange(
                                  e,
                                  index,
                                  "segundoTrimestre"
                                )
                              }
                              onInput={(e) => {
                                const value = e.target.value;
                                if (!/^\d{0,3}$/.test(value)) {
                                  e.target.value = value.slice(0, -1);
                                }
                              }}
                              maxLength="3"
                              required
                            />
                            <label>3er. TRIMESTRE:</label>
                            <textarea
                              name={`calendario.tercerTrimestre.${index}`}
                              value={actividad.calendario.tercerTrimestre}
                              onChange={(e) =>
                                handleCalendarioChange(
                                  e,
                                  index,
                                  "tercerTrimestre"
                                )
                              }
                              onInput={(e) => {
                                const value = e.target.value;
                                if (!/^\d{0,3}$/.test(value)) {
                                  e.target.value = value.slice(0, -1);
                                }
                              }}
                              maxLength="3"
                              required
                            />
                            <label>4o. TRIMESTRE:</label>
                            <textarea
                              name={`calendario.cuartoTrimestre.${index}`}
                              value={actividad.calendario.cuartoTrimestre}
                              onChange={(e) =>
                                handleCalendarioChange(
                                  e,
                                  index,
                                  "cuartoTrimestre"
                                )
                              }
                              onInput={(e) => {
                                const value = e.target.value;
                                if (!/^\d{0,3}$/.test(value)) {
                                  e.target.value = value.slice(0, -1);
                                }
                              }}
                              maxLength="3"
                              required
                            />
                          </div>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <Typography>REALIZADO</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="trimestre-container">
                            <label>1er. TRIMESTRE:</label>
                            <textarea
                              name={`realizado.primerTrimestre.${index}`}
                              value={actividad.realizado.primerTrimestre}
                              onChange={(e) =>
                                handleRealizadoChange(
                                  e,
                                  index,
                                  "primerTrimestre"
                                )
                              }
                              onInput={(e) => {
                                const value = e.target.value;
                                if (!/^\d{0,3}$/.test(value)) {
                                  e.target.value = value.slice(0, -1);
                                }
                              }}
                              maxLength="3"
                              required
                            />
                            <label>2do. TRIMESTRE:</label>
                            <textarea
                              name={`realizado.segundoTrimestre.${index}`}
                              value={actividad.realizado.segundoTrimestre}
                              onChange={(e) =>
                                handleRealizadoChange(
                                  e,
                                  index,
                                  "segundoTrimestre"
                                )
                              }
                              onInput={(e) => {
                                const value = e.target.value;
                                if (!/^\d{0,3}$/.test(value)) {
                                  e.target.value = value.slice(0, -1);
                                }
                              }}
                              maxLength="3"
                              required
                            />
                            <label>3er. TRIMESTRE:</label>
                            <textarea
                              name={`realizado.tercerTrimestre.${index}`}
                              value={actividad.realizado.tercerTrimestre}
                              onChange={(e) =>
                                handleRealizadoChange(
                                  e,
                                  index,
                                  "tercerTrimestre"
                                )
                              }
                              onInput={(e) => {
                                const value = e.target.value;
                                if (!/^\d{0,3}$/.test(value)) {
                                  e.target.value = value.slice(0, -1);
                                }
                              }}
                              maxLength="3"
                              required
                            />
                            <label>4o. TRIMESTRE:</label>
                            <textarea
                              name={`realizado.cuartoTrimestre.${index}`}
                              value={actividad.realizado.cuartoTrimestre}
                              onChange={(e) =>
                                handleRealizadoChange(
                                  e,
                                  index,
                                  "cuartoTrimestre"
                                )
                              }
                              onInput={(e) => {
                                const value = e.target.value;
                                if (!/^\d{0,3}$/.test(value)) {
                                  e.target.value = value.slice(0, -1);
                                }
                              }}
                              maxLength="3"
                              required
                            />
                          </div>
                        </AccordionDetails>
                      </Accordion>
                      <label>TOTAL ACUMULADO:</label>
                      <textarea
                        name="totalAcumulado"
                        value={actividad.totalAcumulado}
                        onChange={(e) => handleActividadChange(e, index)}
                      />
                      <label>% DE AVANCE FÍSICO VS ORIGINAL:</label>
                      <textarea
                        name="avanceFisicoVsOriginal"
                        value={actividad.avanceFisicoVsOriginal}
                        onChange={(e) => handleActividadChange(e, index)}
                      />
                      <label>OBJETIVO DE LA ACTIVIDAD:</label>
                      <textarea
                        name="objetivoActividad"
                        value={actividad.objetivoActividad}
                        onChange={(e) => handleActividadChange(e, index)}
                      />
                      <label>OBSERVACIONES:</label>
                      <textarea
                        name="observaciones"
                        value={actividad.observaciones}
                        onChange={(e) => handleActividadChange(e, index)}
                      />
                    </div>
                  </AccordionDetails>
                </Accordion>
              ))}
              <div className="formulario-pat-buttons">
                <div className="formulario-pat-add-activity">
                  <button type="button" onClick={agregarActividad}>
                    Agregar una actividad
                  </button>
                </div>
                <div className="formulario-pat-delete-activity">
                  <button type="button" onClick={eliminarActividad}>
                    Borrar una actividad
                  </button>
                </div>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Responsable y Titular</Typography>
          </AccordionSummary>
          <div className="columna">
            <label>RESPONSABLE DE LA ELABORACIÓN:</label>
            <textarea
              name="responsableElaboracion"
              value={formData.responsableElaboracion}
              onChange={handleChange}
              required
            />
            <label>TITULAR DE LA DEPENDENCIA:</label>
            <textarea
              name="titularDependencia"
              value={formData.titularDependencia}
              onChange={handleChange}
              required
            />
            <label>USUARIO QUE CREA EL PAT:</label>
            <textarea
              name="nombreUsuario"
              value={formData.userName}
              onChange={handleChange}
              disabled
            />
          </div>
        </Accordion>
        <div className="formulario-pat-submit-container">
          <button type="submit" onClick={handleConfirmDialogOpen}>
            GUARDAR PROGRAMA ANUAL DE TRABAJO
          </button>
        </div>
      </form>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Dialog open={confirmDialogOpen} onClose={handleConfirmDialogClose}>
        <DialogTitle>Agregar Programa Anual de Trabajo</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ textAlign: 'justify' }}>
            <Typography
              component="span"
              variant="body1"
              style={{ fontWeight: "bold"}}
            >
              ¿Está seguro de que desea guardar el Programa Anual de Trabajo?
            </Typography>
            <br />
            Verifique bien los datos agregados a su PAT (Vinculaciones,
            clasificación, actividades, responsable y titular), ya que una vez
            guardado no podrá modificarlos después.
            <br />
          </DialogContentText>
          <TextField
            label="Guardar como... Ejemplo: (Programa Anual de Trabajo 2024)" 
            value={nombrePAT}
            onChange={handleNombrePATChange}
            fullWidth
            margin="dense"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmDialogClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleSubmit} color="primary" autoFocus>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default FormularioPAT;
