import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { db } from "./firebase.js";
import { doc, getDoc, deleteDoc } from "firebase/firestore";
import Header from "./Components/Header.js";
import AOS from "aos";
import "aos/dist/aos.css";
import "./Css/VerPat.css";
import { ArrowBack } from "@mui/icons-material";
import logoIzquierda from "./Images/LogoAyuntamiento.png";
import logoDerecha from "./Images/logo_impl.png";
import {
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
  Snackbar,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import jsPDF from "jspdf";
import "jspdf-autotable";

function VerPAT() {
  const [name, setName] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const { dependencia, docPath } = location.state || {};
  const { patId } = useParams();
  const [patData, setPatData] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);

  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
    const storedName = localStorage.getItem("userName");
    if (storedName) {
      setName(storedName);
    } else {
      navigate("/login");
    }
    const fetchPatData = async () => {
      if (docPath && patId) {
        const patDocRef = doc(db, `${docPath}/Pat`, patId);
        const patDocSnap = await getDoc(patDocRef);
        if (patDocSnap.exists()) {
          setPatData({ id: patDocSnap.id, ...patDocSnap.data() });
        }
      }
    };

    fetchPatData();
  }, [docPath, patId, navigate]);

  const handleLogout = () => {
    localStorage.removeItem("userName");
    navigate("/login");
  };

  const handleBackClick = () => {
    navigate("/directory");
  };

  const generatePDF = async () => {
    const doc = new jsPDF();
    const pageHeight = doc.internal.pageSize.height;
    const pageWidth = doc.internal.pageSize.width;
    let yOffset = -7;

    const logoWidthIzquierda = 28;
    const logoWidthDerecha = 40;
    const logoHeightIzquierda = 30;
    const logoHeightDerecha = 15;

    doc.addImage(
      logoIzquierda,
      "PNG",
      3,
      4,
      logoWidthIzquierda,
      logoHeightIzquierda
    );
    doc.addImage(
      logoDerecha,
      "PNG",
      pageWidth - logoWidthDerecha - 10,
      10,
      logoWidthDerecha,
      logoHeightDerecha
    );
    yOffset += Math.max(logoHeightIzquierda, logoHeightDerecha) + 5;

    const addCenteredText = (text, size, bold = false) => {
      doc.setFontSize(size);
      if (bold) doc.setFont("helvetica", "bold");
      else doc.setFont("helvetica", "normal");
      const textWidth =
        (doc.getStringUnitWidth(text) * size) / doc.internal.scaleFactor;
      const textOffset = (doc.internal.pageSize.width - textWidth) / 2;
      doc.text(text, textOffset, yOffset);
      yOffset += 7;
      if (yOffset >= pageHeight - 10) {
        doc.addPage();
        yOffset = 20;
      }
    };

    const addTable = (title, headers, data) => {
      yOffset += 10;
      doc.setFont("helvetica", "bold");
      doc.setFontSize(12);
      doc.text(title, 15, yOffset);
      yOffset += 5;

      doc.autoTable({
        startY: yOffset,
        head: [headers],
        body: [data],
        theme: "grid",
      });
      yOffset = doc.autoTable.previous.finalY + 5;
      if (yOffset >= pageHeight - 10) {
        doc.addPage();
        yOffset = 10;
      }
    };

    const addActivityTable = (actividad, index) => {
      if (index % 2 === 0 && index !== 0) {
        doc.addPage();
        yOffset = 20;
      }

      const actividadTitulo = `Actividad ${index + 1}`;
      doc.setFont("helvetica", "bold");
      doc.setFontSize(12);
      doc.text(actividadTitulo, 15, yOffset);
      yOffset += 5;

      const headers = ["Descripción", "Fundamentación Jurídica", "Meta Anual", "Unidad de Medida", "Original Anual"];
      const data = [
        actividad.descripcion,
        actividad.fundamentacionJuridica,
        actividad.metaAnual,
        actividad.unidadMedida,
        actividad.originalAnual,
      ];

      doc.autoTable({
        startY: yOffset,
        head: [headers],
        body: [data],
        theme: "grid",
      });
      yOffset = doc.autoTable.previous.finalY + 5;

      // Add Calendario Title
      doc.setFontSize(10);
      doc.text("Programado", 15, yOffset);
      yOffset += 5;

      const calendarioHeaders = [
        "Programado 1er. Trimestre",
        "Programado 2do. Trimestre",
        "Programado 3er. Trimestre",
        "Programado 4o. Trimestre",
      ];
      const calendarioData = [
        actividad.calendario.primerTrimestre,
        actividad.calendario.segundoTrimestre,
        actividad.calendario.tercerTrimestre,
        actividad.calendario.cuartoTrimestre,
      ];

      doc.autoTable({
        startY: yOffset,
        head: [calendarioHeaders],
        body: [calendarioData],
        theme: "grid",
      });
      yOffset = doc.autoTable.previous.finalY + 5;

      // Add Realizado Title
      doc.setFontSize(10);
      doc.text("Realizado", 15, yOffset);
      yOffset += 5;

      const realizadoHeaders = [
        "Realizado 1er. Trimestre",
        "Realizado 2do. Trimestre",
        "Realizado 3er. Trimestre",
        "Realizado 4o. Trimestre",
      ];
      const realizadoData = [
        actividad.realizado.primerTrimestre,
        actividad.realizado.segundoTrimestre,
        actividad.realizado.tercerTrimestre,
        actividad.realizado.cuartoTrimestre,
      ];

      // Colorea las celdas
      const realizadoRowStyles = realizadoData.map((valor, i) => {
        const planificadoValor = calendarioData[i];
        const { backgroundColor, color } = evaluateAndColorize(
          valor,
          planificadoValor
        );
        return {
          fillColor: backgroundColor,
          textColor: color,
        };
      });

      doc.autoTable({
        startY: yOffset,
        head: [realizadoHeaders],
        body: [realizadoData.map((d, i) => [d])],
        theme: "grid",
        styles: {
          halign: "center",
        },
        columnStyles: {
          0: realizadoRowStyles[0],
          1: realizadoRowStyles[1],
          2: realizadoRowStyles[2],
          3: realizadoRowStyles[3],
        },
      });
      yOffset = doc.autoTable.previous.finalY + 5;

      // Add Resultados Title
      doc.setFontSize(10);
      doc.text("Evaluación de resultados", 15, yOffset);
      yOffset += 5;

      const finalHeaders = [
        "Total Acumulado",
        "% Avance Físico",
        "Objetivo Actividad",
        "Observaciones",
      ];
      const finalData = [
        actividad.totalAcumulado,
        actividad.avanceFisicoVsOriginal,
        actividad.objetivoActividad,
        actividad.observaciones,
      ];

      doc.autoTable({
        startY: yOffset,
        head: [finalHeaders],
        body: [finalData],
        theme: "grid",
      });
      yOffset = doc.autoTable.previous.finalY + 20;
    };

    addCenteredText("H AYUNTAMIENTO DE XALISCO", 12, true);
    addCenteredText(
      `UNIDAD RESPONSABLE "${dependencia.toUpperCase()}"`,
      12,
      true
    );
    addCenteredText("PROGRAMA ANUAL DE TRABAJO", 12, true);

    if (patData) {
      addTable(
        "Vinculación con el Plan Municipal de Desarrollo 2021-2024",
        ["Eje Rector", "Línea de Acción", "Programa"],
        [patData.ejeRectorPMD, patData.lineaAccionPMD, patData.programaPMD]
      );

      addTable(
        "Vinculación con el Plan Nacional de Desarrollo 2019-2024",
        ["Eje General", "Eje Transversal"],
        [patData.ejeGeneralPND, patData.ejeTransversalPND]
      );

      addTable(
        "Vinculación con el Plan Estatal de Desarrollo 2021-2027",
        [
          "Eje Rector",
          "Eje General",
          "Programa",
          "Eje Transversal",
          "Programa Eje",
        ],
        [
          patData.ejeRectorPDE,
          patData.ejeGeneralPDE,
          patData.programaPDE,
          patData.ejeTransversalPDE,
          patData.programaEjePDE,
        ]
      );

      addTable(
        "Clasificación Funcional",
        ["Finalidad", "Función", "Subfunción"],
        [patData.finalidadCF, patData.funcionCF, patData.subfuncionCF]
      );

      // Agregar firmas
      const signatureYOffset = yOffset + 40;
      const signatureLineLengthResponsable = 60;
      const signatureLineLengthTitular = 70;
      const signatureTextOffset = 5;
      const minimumSpaceBetweenSignatures = 45;

      // Responsable Elaboración
      const responsableXPosition = 15;

      doc.line(
        responsableXPosition,
        signatureYOffset,
        responsableXPosition + signatureLineLengthResponsable,
        signatureYOffset
      );
      doc.setFontSize(10);
      doc.text(
        patData.responsableElaboracion,
        responsableXPosition,
        signatureYOffset + signatureTextOffset
      );
      doc.text(
        "Responsable Elaboración",
        responsableXPosition,
        signatureYOffset + signatureTextOffset + 5
      );

      // Titular Dependencia
      const titularXPosition =
        responsableXPosition +
        signatureLineLengthResponsable +
        minimumSpaceBetweenSignatures;

      doc.line(
        titularXPosition,
        signatureYOffset,
        titularXPosition + signatureLineLengthTitular,
        signatureYOffset
      );
      doc.text(
        patData.titularDependencia,
        titularXPosition,
        signatureYOffset + signatureTextOffset
      );
      doc.text(
        "Titular Dependencia",
        titularXPosition,
        signatureYOffset + signatureTextOffset + 5
      );

      doc.addPage();
      yOffset = 20;

      patData.actividades.forEach((actividad, index) => {
        addActivityTable(actividad, index);
      });
    }

    doc.save(`Programa Anual de Trabajo de ${dependencia.toUpperCase()}.pdf`);
  };

  const evaluateAndColorize = (realizado, planificado) => {
    if (!realizado || !planificado)
      return { backgroundColor: "white", color: "black" }; // color por defecto
    const realizadoNum = parseInt(realizado, 10);
    const planificadoNum = parseInt(planificado, 10);

    if (realizadoNum >= planificadoNum) {
      return { backgroundColor: "green", color: "white" }; // verde con texto blanco
    } else if (realizadoNum >= planificadoNum * 0.5) {
      return { backgroundColor: "yellow", color: "black" }; // amarillo con texto negro
    } else {
      return { backgroundColor: "red", color: "white" }; // rojo con texto blanco
    }
  };

  const deletePAT = async () => {
    if (name !== patData.userName) {
      setSnackbarMessage("ESTE USUARIO NO PUEDE ELIMINAR ESTE PROGRAMA ANUAL");
      setSnackbarOpen(true);
      return;
    }
    setConfirmDialogOpen(true);
  };

  const handleConfirmDelete = async () => {
    if (docPath && patId) {
      const patDocRef = doc(db, `${docPath}/Pat`, patId);
      await deleteDoc(patDocRef);
      setSuccessSnackbarOpen(true); 
      setTimeout(() => {
        navigate("/directory");
      }, 4000);  
    }
    setConfirmDialogOpen(false);
  };
  
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleConfirmDialogClose = () => {
    setConfirmDialogOpen(false);
  };

  const handleSuccessSnackbarClose = () => {
    setSuccessSnackbarOpen(false);
  };
  
  return (
    <div className="ver-pat-container">
      <Header name={name} handleLogout={handleLogout} />
      <img
        src={logoIzquierda}
        alt="Logo Izquierdo"
        className="logo-izquierda"
        data-aos="fade-up"
      />
      <img
        src={logoDerecha}
        alt="Logo Derecho"
        className="logo-derecha"
        data-aos="fade-up"
      />
      <div className="ver-pat-back-button-container">
        <IconButton onClick={handleBackClick} data-aos="zoom-in">
          <ArrowBack />
        </IconButton>
      </div>
      <div className="formulario-pat-title" data-aos="zoom-in">
        <h1>XLII AYUNTAMIENTO DE XALISCO</h1>
        <h1>PROGRAMA ANUAL DE TRABAJO</h1>
        <h3>DEPENDENCIA XLII AYUNTAMIENTO DE XALISCO</h3>
        <h3>UNIDAD RESPONSABLE {dependencia.toUpperCase()}</h3>
      </div>
      <div
        className="ver-pat-content"
        id="pdf-content"
        data-aos="fade-left"
        delay="1000"
      >
        {patData ? (
          <div className="pat-grid">
            <Button
              variant="contained"
              color="primary"
              onClick={generatePDF}
              data-aos="zoom-in"
            >
              GENERAR PAT EN PDF
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={deletePAT}
              data-aos="zoom-in"
            >
              ELIMINAR PAT 
            </Button>
            <div className="pat-category">
              <h3>Vinculación con el Plan Municipal de Desarrollo 2021-2024</h3>
              <div className="pat-grid-item">
                Eje Rector: {patData.ejeRectorPMD}
              </div>
              <div className="pat-grid-item">
                Línea de Acción: {patData.lineaAccionPMD}
              </div>
              <div className="pat-grid-item">
                Programa: {patData.programaPMD}
              </div>
            </div>
            <div className="pat-category">
              <h3>Vinculación con el Plan Nacional de Desarrollo 2019-2024</h3>
              <div className="pat-grid-item">
                Eje General: {patData.ejeGeneralPND}
              </div>
              <div className="pat-grid-item">
                Eje Transversal: {patData.ejeTransversalPND}
              </div>
            </div>
            <div className="pat-category">
              <h3>Vinculación con el Plan Estatal de Desarrollo 2021-2027</h3>
              <div className="pat-grid-item">
                Eje Rector: {patData.ejeRectorPDE}
              </div>
              <div className="pat-grid-item">
                Eje General: {patData.ejeGeneralPDE}
              </div>
              <div className="pat-grid-item">
                Programa: {patData.programaPDE}
              </div>
              <div className="pat-grid-item">
                Eje Transversal: {patData.ejeTransversalPDE}
              </div>
              <div className="pat-grid-item">
                Programa Eje: {patData.programaEjePDE}
              </div>
            </div>
            <div className="pat-category">
              <h3>Clasificación Funcional</h3>
              <div className="pat-grid-item">
                Finalidad: {patData.finalidadCF}
              </div>
              <div className="pat-grid-item">Función: {patData.funcionCF}</div>
              <div className="pat-grid-item">
                Subfunción: {patData.subfuncionCF}
              </div>
            </div>
            <div className="pat-category">
              <h3>Responsable y Titular de la dependencia</h3>
              <div className="pat-grid-item">
                Responsable de la elaboración: {patData.responsableElaboracion}
              </div>
              <div className="pat-grid-item">
                Titular de la dependencia: {patData.titularDependencia}
              </div>
            </div>
            <div className="pat-category">
              <h3>Actividades</h3>
              {patData.actividades &&
                patData.actividades.map((actividad, index) => (
                  <Accordion key={index} className="avance-pat-accordion">
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      className="pat-accordion-summary"
                    >
                      <Typography>Actividad No. {index + 1}</Typography>
                    </AccordionSummary>
                    <AccordionDetails className="pat-accordion-details">
                      <div className="actividad">
                        <div className="pat-grid-item">
                          Descripción: {actividad.descripcion}
                        </div>
                        <div className="pat-grid-item">
                          Fundamentación Jurídica:{" "}
                          {actividad.fundamentacionJuridica}
                        </div>
                        <div className="pat-grid-item">
                          Meta Anual: {actividad.metaAnual}
                        </div>
                        <div className="pat-grid-item">
                          Unidad de Medida: {actividad.unidadMedida}
                        </div>
                        <div className="pat-grid-item">
                          Original Anual: {actividad.originalAnual}
                        </div>
                        {/* Mostrar CALENDARIO */}
                        <Accordion className="pat-accordion">
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="pat-accordion-summary"
                          >
                            <Typography>PROGRAMADO</Typography>
                          </AccordionSummary>
                          <AccordionDetails className="pat-accordion-details">
                            <div className="trimestre-container">
                              <div className="pat-grid-item">
                                1er. TRIMESTRE:{" "}
                                {actividad.calendario.primerTrimestre}
                              </div>
                              <div className="pat-grid-item">
                                2do. TRIMESTRE:{" "}
                                {actividad.calendario.segundoTrimestre}
                              </div>
                              <div className="pat-grid-item">
                                3er. TRIMESTRE:{" "}
                                {actividad.calendario.tercerTrimestre}
                              </div>
                              <div className="pat-grid-item">
                                4o. TRIMESTRE:{" "}
                                {actividad.calendario.cuartoTrimestre}
                              </div>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        {/* Mostrar REALIZADO */}
                        <Accordion className="pat-accordion">
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="pat-accordion-summary"
                          >
                            <Typography>REALIZADO</Typography>
                          </AccordionSummary>
                          <AccordionDetails className="pat-accordion-details">
                            <div className="trimestre-container">
                              <div
                                className="pat-grid-item"
                                style={evaluateAndColorize(
                                  actividad.realizado.primerTrimestre,
                                  actividad.calendario.primerTrimestre
                                )}
                              >
                                1er. TRIMESTRE:{" "}
                                {actividad.realizado.primerTrimestre}
                              </div>
                              <div
                                className="pat-grid-item"
                                style={evaluateAndColorize(
                                  actividad.realizado.segundoTrimestre,
                                  actividad.calendario.segundoTrimestre
                                )}
                              >
                                2do. TRIMESTRE:{" "}
                                {actividad.realizado.segundoTrimestre}
                              </div>
                              <div
                                className="pat-grid-item"
                                style={evaluateAndColorize(
                                  actividad.realizado.tercerTrimestre,
                                  actividad.calendario.tercerTrimestre
                                )}
                              >
                                3er. TRIMESTRE:{" "}
                                {actividad.realizado.tercerTrimestre}
                              </div>
                              <div
                                className="pat-grid-item"
                                style={evaluateAndColorize(
                                  actividad.realizado.cuartoTrimestre,
                                  actividad.calendario.cuartoTrimestre
                                )}
                              >
                                4o. TRIMESTRE:{" "}
                                {actividad.realizado.cuartoTrimestre}
                              </div>
                            </div>
                          </AccordionDetails>
                        </Accordion>

                        <div className="pat-grid-item">
                          TOTAL ACUMULADO: {actividad.totalAcumulado}
                        </div>
                        <div className="pat-grid-item">
                          % DE AVANCE FÍSICO: {actividad.avanceFisicoVsOriginal}
                        </div>
                        <div className="pat-grid-item">
                          OBJETIVO DE LA ACTIVIDAD:{" "}
                          {actividad.objetivoActividad}
                        </div>
                        <div className="pat-grid-item">
                          OBSERVACIONES: {actividad.observaciones}
                        </div>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                ))}
            </div>
          </div>
        ) : (
          <p>Aún no se ha agregado ningún Programa Anual de Trabajo</p>
        )}
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleSnackbarClose} severity="error">
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={successSnackbarOpen}
        autoHideDuration={4000}
        onClose={handleSuccessSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleSuccessSnackbarClose} severity="success">
          Programa Anual de Trabajo eliminado correctamente
        </Alert>
      </Snackbar>
      <Dialog
        open={confirmDialogOpen}
        onClose={handleConfirmDialogClose}
      >
        <DialogTitle>Confirmar Eliminación</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Está seguro que desea eliminar este Programa Anual de Trabajo?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmDialogClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleConfirmDelete} color="primary">
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default VerPAT;
