import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Home";
import Login from "./Login";
import Register from "./Register";
import ForgotP from "./ForgotPassword";
import Directory from "./Directory";
import BuenGobierno from "./BuenGobierno";
import XaliscoDesarrollo from "./XaliscoDesarrollo";
import CrecimientoEconomico from "./CrecimientoEconomico";
import XaliscoCercaTi from "./XaliscoCercaTi";
import FormularioPAT from "./FormularioPAT";
import VerPAT from "./VerPat";
import AvacePAT from "./AvancePAT";
import AdminPanel from "./AdminPanel";
import PrivateRoute from "./Components/PrivateRoute";
import ResetPassword from "./ResetPassword";
import "./Css/App.css";

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgotPassword" element={<ForgotP />} />
          <Route path="/directory" element={<Directory />} />
          <Route path="/buen-gobierno" element={<BuenGobierno />} />
          <Route path="/xalisco-desarrollo" element={<XaliscoDesarrollo />} />
          <Route
            path="/crecimiento-economico"
            element={<CrecimientoEconomico />}
          />
          <Route path="/xalisco-cerca-ti" element={<XaliscoCercaTi />} />
          <Route path="/formulario-pat" element={<FormularioPAT />} />
          <Route path="/ver-pat/:patId" element={<VerPAT />} />
          <Route path="/avance-pat/:patId" element={<AvacePAT />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route element={<PrivateRoute allowedRoles={["admin"]} />}>
            <Route path="/admin-panel" element={<AdminPanel />} />
            <Route path="/register" element={<Register />} />
          </Route>
        </Routes>
      </div>
    </Router>
  );
}

export default App;
