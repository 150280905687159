import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import { TextField, InputAdornment, IconButton, Snackbar } from "@mui/material";
import { Alert } from "@mui/material";
import {
  AccountCircle,
  Email,
  Lock,
  ArrowBack,
  Visibility,
  VisibilityOff,
  Work,
} from "@mui/icons-material";
import "./Css/Register.css";
import { db } from "./firebase.js";
import { collection, addDoc, query, where, getDocs } from "firebase/firestore";

function Register() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    dependencia: "",
  });
  const [setError] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openPasswordMismatchSnackbar, setOpenPasswordMismatchSnackbar] =
    useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);

  const handleBackAdminPanelClick = () => {
    navigate("/admin-panel");
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData({
      ...formData,
      [id]: value,
    });
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleToggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleClosePasswordMismatchSnackbar = () => {
    setOpenPasswordMismatchSnackbar(false);
  };

  const handleCloseErrorSnackbar = () => {
    setOpenErrorSnackbar(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      formData.name === "" ||
      formData.email === "" ||
      formData.password === "" ||
      formData.confirmPassword === "" ||
      formData.dependencia === ""
    ) {
      setError("Por favor completa todos los campos.");
      setOpenSnackbar(true);
      return;
    }
    if (formData.password !== formData.confirmPassword) {
      setErrorMessage("Las contraseñas no coinciden.");
      setOpenPasswordMismatchSnackbar(true);
      return;
    }

    try {
      const usersRef = collection(db, "Usuarios");
      const q = query(usersRef, where("email", "==", formData.email));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        setErrorMessage("El correo ya está registrado.");
        setOpenErrorSnackbar(true);
        return;
      }

      const docRef = await addDoc(usersRef, formData);
      console.log("Documento creado con ID: ", docRef.id);
      setSuccessMessage("Usuario registrado con éxito.");
      setOpenSnackbar(true);
      setTimeout(() => {
        navigate("/login");
      }, 2000);
    } catch (error) {
      console.error("Error creando documento: ", error);
      setErrorMessage(
        "Error al registrar. Por favor intenta de nuevo más tarde."
      );
      setOpenErrorSnackbar(true);
    }
  };

  return (
    <div className="register-page">
      <div className="register-back-button-container">
        <IconButton onClick={handleBackAdminPanelClick} data-aos="fade-up">
          <ArrowBack />
        </IconButton>
      </div>
      <div className="register-container" data-aos="fade-up">
        <div className="register-image" data-aos="fade-right">
        </div>
        <div className="register-form" data-aos="fade-left">
          <h2>Registrar usuario</h2>
          <form onSubmit={handleSubmit}>
            <div className="register-form-group" data-aos="fade-up" data-aos-delay="100">
              <label htmlFor="name">Nombre completo</label>
              <TextField
                id="name"
                placeholder="Nombre completo"
                variant="outlined"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircle />
                    </InputAdornment>
                  ),
                }}
                required
                value={formData.name}
                onChange={handleInputChange}
              />
            </div>
            <div className="register-form-group" data-aos="fade-up" data-aos-delay="200">
              <label htmlFor="dependencia">Dependencia</label>
              <TextField
                id="dependencia"
                placeholder="Dependencia"
                variant="outlined"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Work />
                    </InputAdornment>
                  ),
                }}
                required
                value={formData.dependencia}
                onChange={handleInputChange}
              />
            </div>
            <div className="register-form-group" data-aos="fade-up" data-aos-delay="300">
              <label htmlFor="email">Correo electrónico</label>
              <TextField
                type="email"
                id="email"
                placeholder="Correo electrónico"
                variant="outlined"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Email />
                    </InputAdornment>
                  ),
                }}
                required
                value={formData.email}
                onChange={handleInputChange}
              />
            </div>
            <div className="register-form-group" data-aos="fade-up" data-aos-delay="400">
              <label htmlFor="password">Contraseña</label>
              <TextField
                id="password"
                type={showPassword ? "text" : "password"}
                placeholder="Contraseña"
                variant="outlined"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Lock />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleTogglePasswordVisibility}>
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                required
                value={formData.password}
                onChange={handleInputChange}
              />
            </div>
            <div className="register-form-group" data-aos="fade-up" data-aos-delay="500">
              <label htmlFor="confirmPassword">Confirmar contraseña</label>
              <TextField
                id="confirmPassword"
                type={showConfirmPassword ? "text" : "password"}
                placeholder="Confirmar contraseña"
                variant="outlined"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Lock />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleToggleConfirmPasswordVisibility}
                      >
                        {showConfirmPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                required
                value={formData.confirmPassword}
                onChange={handleInputChange}
              />
            </div>
            <button type="submit" data-aos="fade-up" data-aos-delay="600">
              Registrarse
            </button>
          </form>
        </div>
      </div>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          sx={{ width: "100%", backgroundColor: "#4CAF50" }}
        >
          {successMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={openPasswordMismatchSnackbar}
        autoHideDuration={6000}
        onClose={handleClosePasswordMismatchSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleClosePasswordMismatchSnackbar}
          severity="error"
          sx={{ width: "100%", backgroundColor: "#D7E10C" }}
        >
          Las contraseñas no coinciden.
        </Alert>
      </Snackbar>
      <Snackbar
        open={openErrorSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseErrorSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseErrorSnackbar}
          severity="error"
          sx={{ width: "100%", backgroundColor: "#F44336" }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default Register;
