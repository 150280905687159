import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";
import AOS from "aos";
import "aos/dist/aos.css";
import {
  IconButton,
  Menu,
  MenuItem,
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  Button,
} from "@mui/material";
import Header from "./Components/Header.js";
import "./Css/EjesRectores.css";
import { db } from "./firebase.js";
import { collection, query, getDocs, doc, getDoc } from "firebase/firestore";

function BuenGobierno() {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [lineaAccionE1AData, setLineaAccionE1AData] = useState([]);
  const [lineaAccionE1BData, setLineaAccionE1BData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedDependencia, setSelectedDependencia] = useState("");
  const [selectedDocPath, setSelectedDocPath] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [ejeRectorPMD, setEjeRectorPMD] = useState("");
  const [lineaAccionPMD, setLineaAccionPMD] = useState("");
  const [programaPMD, setProgramaPMD] = useState("");
  const [patListDialogOpen, setPatListDialogOpen] = useState(false);
  const [patDocuments, setPatDocuments] = useState([]);
  const [menuAction, setMenuAction] = useState("");

  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
    const storedName = localStorage.getItem("userName");
    if (storedName) {
      setName(storedName);
    } else {
      navigate("/login");
    }

    const fetchData = async () => {
      const qE1A = query(collection(db, "EjeRector1", "E1-A", "LineaAccion"));
      const querySnapshotE1A = await getDocs(qE1A);
      const dataE1A = querySnapshotE1A.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      dataE1A.sort((a, b) => {
        const numA = parseInt(a.descripcion.split(".")[0], 10);
        const numB = parseInt(b.descripcion.split(".")[0], 10);
        return numA - numB;
      });
      setLineaAccionE1AData(dataE1A);

      const qE1B = query(collection(db, "EjeRector1", "E1-B", "LineaAccion"));
      const querySnapshotE1B = await getDocs(qE1B);
      const dataE1B = querySnapshotE1B.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      dataE1B.sort((a, b) => {
        const numA = parseInt(a.descripcion.split(".")[0], 10);
        const numB = parseInt(b.descripcion.split(".")[0], 10);
        return numA - numB;
      });
      setLineaAccionE1BData(dataE1B);
    };

    fetchData();
  }, [navigate]);

  const handleLogout = () => {
    localStorage.removeItem("userName");
    navigate("/login");
  };

  const handleBackClick = () => {
    navigate("/directory");
  };

  const handleCardClickE1A = async (event, dependencia, docId) => {
    setSelectedDependencia(dependencia);
    setSelectedDocPath(`EjeRector1/E1-A/LineaAccion/${docId}`);
    setAnchorEl(event.currentTarget);

    const docRef = doc(db, `EjeRector1/E1-A/LineaAccion`, docId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const data = docSnap.data();
      setEjeRectorPMD(data.ejeRectorPMD || "");
      setLineaAccionPMD(data.lineaAccionPMD || "");
      setProgramaPMD(data.programaPMD || "");
    }
  };

  const handleCardClickE1B = async (event, dependencia, docId) => {
    setSelectedDependencia(dependencia);
    setSelectedDocPath(`EjeRector1/E1-B/LineaAccion/${docId}`);
    setAnchorEl(event.currentTarget);

    const docRef = doc(db, `EjeRector1/E1-B/LineaAccion`, docId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const data = docSnap.data();
      setEjeRectorPMD(data.ejeRectorPMD || "");
      setLineaAccionPMD(data.lineaAccionPMD || "");
      setProgramaPMD(data.programaPMD || "");
    }
  };

  const handleMenuClose = async (action) => {
    setAnchorEl(null);
    setMenuAction(action); 
    if (action === "add") {
      navigate("/formulario-pat", {
        state: {
          dependencia: selectedDependencia,
          docPath: selectedDocPath,
          ejeRectorPMD,
          lineaAccionPMD,
          programaPMD,
        },
      });
    } else if (action === "view" || action === "progress") {
      const patRef = collection(db, selectedDocPath, "Pat");
      const patSnapshot = await getDocs(patRef);
      const patDocuments = patSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      if (patDocuments.length === 0) {
        setSnackbarOpen(true);
      } else if (patDocuments.length === 1) {
        const patId = patDocuments[0].id;
        const route =
          action === "view" ? `/ver-pat/${patId}` : `/avance-pat/${patId}`;
        navigate(route, {
          state: { dependencia: selectedDependencia, docPath: selectedDocPath },
        });
      } else if (patDocuments.length > 1) {
        setPatDocuments(patDocuments);
        setPatListDialogOpen(true);
      }
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handlePatListItemClick = (patId) => {
    if (menuAction === "view") {
      navigate(`/ver-pat/${patId}`, {
        state: {
          dependencia: selectedDependencia,
          docPath: selectedDocPath,
          ejeRectorPMD,
          lineaAccionPMD,
          programaPMD,
        },
      });
    } else if (menuAction === "progress") {
      navigate(`/avance-pat/${patId}`, {
        state: {
          dependencia: selectedDependencia,
          docPath: selectedDocPath,
          ejeRectorPMD,
          lineaAccionPMD,
          programaPMD,
        },
      });
    }
    setPatListDialogOpen(false);
  };

  return (
    <div className="ejes-rectores-page">
      <Header name={name} handleLogout={handleLogout} />
      <div className="ejes-rectores-back-button-container">
        <IconButton onClick={handleBackClick} data-aos="fade-up">
          <ArrowBack />
        </IconButton>
      </div>
      <div className="ejes-rectores-content-container" data-aos="fade-up">
        <h1>EJE RECTOR. E1. BUEN GOBIERNO Y MUNICIPIO SEGURO</h1>
        <h2>I. Línea de acción. E1-A. Gobierno eficiente</h2>
        <div className="ejes-rectores-card-container">
          {lineaAccionE1AData.map((item, index) => (
            <div
              className="ejes-rectores-card"
              key={index}
              onClick={(e) => handleCardClickE1A(e, item.dependencia, item.id)}
            >
              <strong>{item.dependencia}</strong>
              <p>{item.descripcion}</p>
            </div>
          ))}
        </div>
        <h2>II. Línea de acción. E1-B. Municipio seguro</h2>
        <div className="ejes-rectores-card-container">
          {lineaAccionE1BData.map((item, index) => (
            <div
              className="ejes-rectores-card"
              key={index}
              onClick={(e) => handleCardClickE1B(e, item.dependencia, item.id)}
            >
              <strong>{item.dependencia}</strong>
              <p>{item.descripcion}</p>
            </div>
          ))}
        </div>
      </div>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => handleMenuClose(null)}
      >
        <MenuItem onClick={() => handleMenuClose("view")}>
          Ver Programa Anual de Trabajo
        </MenuItem>
        <MenuItem onClick={() => handleMenuClose("add")}>
          Agregar Programa Anual de Trabajo
        </MenuItem>
        <MenuItem onClick={() => handleMenuClose("progress")}>
          Agregar Avance al Programa Anual de Trabajo
        </MenuItem>
        <MenuItem onClick={() => handleMenuClose(null)}>Cancelar</MenuItem>
      </Menu>
      <Dialog
        open={patListDialogOpen}
        onClose={() => setPatListDialogOpen(false)}
      >
        <DialogTitle>
          Selecciona el Programa Anual de Trabajo que desea ver:
        </DialogTitle>
        <DialogContent>
          <List>
            {patDocuments.map((pat) => (
              <ListItem
                button
                key={pat.id}
                onClick={() => handlePatListItemClick(pat.id)}
              >
                <ListItemText primary={pat.nombrePAT} />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPatListDialogOpen(false)}>Cancelar</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleSnackbarClose} severity="warning">
          No hay ningún PAT en este Programa.
        </Alert>
      </Snackbar>
    </div>
  );
}

export default BuenGobierno;
