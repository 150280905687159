import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAHUl34l078wdRHOra8mmTqfec2KvHJAkw",
  authDomain: "sirax-xalisco.firebaseapp.com",
  projectId: "sirax-xalisco",
  storageBucket: "sirax-xalisco.appspot.com",
  messagingSenderId: "98937549472",
  appId: "1:98937549472:web:ecb5c4539746f93ee00fc5",
  measurementId: "G-NF4YV15XMZ"
};

const app = initializeApp(firebaseConfig);

const storage = getStorage(app);
const db = getFirestore(app);

export {db, storage};