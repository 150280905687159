import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const getRole = () => {
  return localStorage.getItem("userRole");
};

const PrivateRoute = ({ allowedRoles }) => {
  const role = getRole();
  return allowedRoles.includes(role) ? (
    <Outlet />
  ) : (
    <Navigate
      to="/directory"
      state={{ error: "No tiene acceso a esta página" }}
    />
  );
};

export default PrivateRoute;
