import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { db } from "./firebase.js";
import {
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import { Snackbar } from "@mui/material";
import { Alert } from "@mui/material";
import "./Css/ResetPassword.css";
import AOS from "aos";
import "aos/dist/aos.css";

function ResetPassword() {
  const navigate = useNavigate();
  const location = useLocation();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const token = new URLSearchParams(location.search).get("token");

  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleCloseErrorSnackbar = () => {
    setOpenErrorSnackbar(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setErrorMessage("Las contraseñas no coinciden.");
      setOpenErrorSnackbar(true);
      return;
    }

    try {
      const q = query(
        collection(db, "RecuperarContra"),
        where("token", "==", token)
      );
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        setErrorMessage("Enlace de restablecimiento no válido o expirado.");
        setOpenErrorSnackbar(true);
        return;
      }

      const docRef = querySnapshot.docs[0].ref;
      const userEmail = querySnapshot.docs[0].data().correo;

      const userQuery = query(
        collection(db, "Usuarios"),
        where("email", "==", userEmail)
      );
      const userSnapshot = await getDocs(userQuery);

      if (userSnapshot.empty) {
        setErrorMessage("Usuario no encontrado.");
        setOpenErrorSnackbar(true);
        return;
      }

      const userDocRef = userSnapshot.docs[0].ref;

      await updateDoc(userDocRef, {
        password,
      });

      await deleteDoc(docRef);

      setSuccessMessage("Contraseña restablecida con éxito.");
      setOpenSnackbar(true);
      setTimeout(() => {
        navigate("/login");
      }, 2000);
    } catch (error) {
      console.error("Error restableciendo la contraseña: ", error);
      setErrorMessage(
        "Error al restablecer la contraseña. Por favor intenta de nuevo más tarde."
      );
      setOpenErrorSnackbar(true);
    }
  };

  return (
    <div className="resetPassword-page">
      <div className="resetPassword-container" data-aos="fade-up">
        <div className="resetPassword-image" data-aos="fade-right">
          {" "}
        </div>
        <div className="resetPassword-form" data-aos="fade-left">
          <h2>Restablecer Contraseña</h2>
          <p>
            Escriba su nueva contraseña que usará para iniciar sesión.
          </p>
          <form onSubmit={handleSubmit}>
            <div
              className="resetPassword-form-group"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <input
                type="password"
                id="password"
                placeholder="Nueva Contraseña"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="resetPassword-form-group">
              <input
                type="password"
                id="confirmPassword"
                placeholder="Confirmar Nueva Contraseña"
                required
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>
            <button type="submit" data-aos="fade-up" data-aos-delay="200">
              Restablecer Contraseña
            </button>
          </form>
        </div>
      </div>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          sx={{ width: "100%", backgroundColor: "#4CAF50" }}
        >
          {successMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={openErrorSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseErrorSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseErrorSnackbar}
          severity="error"
          sx={{ width: "100%", backgroundColor: "#F44336" }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default ResetPassword;
