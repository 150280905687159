import React, { useEffect, useState } from "react";
import "./Css/ForgotPassword.css";
import { useNavigate } from "react-router-dom";
import { IconButton, Snackbar } from "@mui/material";
import { ArrowBack, Email } from "@mui/icons-material";
import { Alert } from "@mui/material";
import AOS from "aos";
import "aos/dist/aos.css";
import { db } from "./firebase.js";
import { collection, addDoc } from "firebase/firestore";
import { v4 as uuidv4 } from "uuid";
import emailjs from "emailjs-com";

function ForgotPassword() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);

  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);

  const handleBackClick = () => {
    navigate("/");
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleCloseErrorSnackbar = () => {
    setOpenErrorSnackbar(false);
  };

  const sendPasswordResetEmail = async (email, token) => {
    const templateParams = {
      to_email: email,
      token: token,
    };

    try {
      await emailjs.send(
        "service_b0vcshp",
        "template_19556n5",
        templateParams,
        "qsHlcEqLZLr4RtHtd"
      );
    } catch (error) {
      console.error("Error al enviar el correo electrónico:", error);
      throw new Error("Error al enviar el correo electrónico");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const token = uuidv4();
      await addDoc(collection(db, "RecuperarContra"), {
        correo: email,
        token,
        createdAt: new Date(),
      });
      await sendPasswordResetEmail(email, token);
      setSuccessMessage("Correo enviado con éxito.");
      setOpenSnackbar(true);
      setTimeout(() => {
        navigate("/login");
      }, 2000);
    } catch (error) {
      console.error("Error creando documento: ", error);
      setErrorMessage(
        "Error al mandar correo. Por favor intenta de nuevo más tarde."
      );
      setOpenErrorSnackbar(true);
    }
  };

  return (
    <div className="forgotPassword-page">
      <div className="forgotPassword-back-button-container">
        <IconButton onClick={handleBackClick} data-aos="fade-up">
          <ArrowBack />
        </IconButton>
      </div>
      <div className="forgotPassword-container" data-aos="fade-up">
        <div className="forgotPassword-image" data-aos="fade-right"></div>
        <div className="forgotPassword-form" data-aos="fade-left">
          <h2>¿Olvidaste tu contraseña?</h2>
          <p>
            Escríbenos tu correo electrónico y te enviaremos un enlace para
            restablecer tu contraseña.
          </p>
          <form onSubmit={handleSubmit}>
            <div className="forgotPassword-form-group" data-aos="fade-up" data-aos-delay="100">
              <input
                type="email"
                id="email"
                placeholder="Correo electrónico"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Email className="icon" />
            </div>
            <button type="submit" data-aos="fade-up" data-aos-delay="200">
              Enviar correo para restablecer contraseña
            </button>
          </form>
        </div>
      </div>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          sx={{ width: "100%", backgroundColor: "#4CAF50" }}
        >
          {successMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={openErrorSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseErrorSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseErrorSnackbar}
          severity="error"
          sx={{ width: "100%", backgroundColor: "#F44336" }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default ForgotPassword;
