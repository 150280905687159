import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Header from './Components/Header.js';
import EjeR1 from './Images/EjeR1.jpg';
import EjeR2 from './Images/EjeR2.jpg';
import EjeR3 from './Images/EjeR3.jpg';
import EjeR4 from './Images/EjeR4.jpg';
import './Css/Directory.css';

function Directory() {
  const navigate = useNavigate();
  const [name, setName] = useState('');

  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
    const storedName = localStorage.getItem('userName');
    if (storedName) {
      setName(storedName);
    } else {
      navigate('/login');
    }
  }, [navigate]);

  const handleLogout = () => {
    localStorage.removeItem('userName');
    navigate('/login');
  };

  const handleCardClick = (path) => {
    navigate(path);
  };

  return (
    <div className="directory-page" >
      <Header name={name} handleLogout={handleLogout} />
      <div className="directory-content" data-aos="fade-up">
        <div className="card" onClick={() => handleCardClick('/buen-gobierno')}>
          <h2>EJE RECTOR. E1.</h2>
          <h3>BUEN GOBIERNO Y MUNICIPIO SEGURO</h3>
          <img src={EjeR1} alt="Buen Gobierno" />
        </div>
        <div className="card" onClick={() => handleCardClick('/xalisco-desarrollo')}>
          <h2>EJE RECTOR. E2.</h2>
          <h3>XALISCO EN DESARROLLO</h3>
          <img src={EjeR2} alt="Xalisco en Desarrollo" />
        </div>
        <div className="card" onClick={() => handleCardClick('/crecimiento-economico')}>
          <h2>EJE RECTOR. E3.</h2>
          <h3>CRECIMIENTO ECONÓMICO</h3>
          <img src={EjeR3} alt="Crecimiento Económico" />
        </div>
        <div className="card" onClick={() => handleCardClick('/xalisco-cerca-ti')}>
          <h2>EJE RECTOR. E4.</h2>
          <h3>XALISCO CERCA DE TI</h3>
          <img src={EjeR4} alt="Xalisco Cerca de Ti" />
        </div>
      </div>
    </div>
  );
}

export default Directory;
