import React, { useEffect, useState } from "react";
import "./Css/Login.css";
import { useNavigate } from "react-router-dom";
import { IconButton, Snackbar } from "@mui/material";
import { ArrowBack, Email, Lock } from "@mui/icons-material";
import { Alert } from "@mui/material";
import AOS from "aos";
import "aos/dist/aos.css";
import { db } from "./firebase.js";
import { collection, query, where, getDocs } from "firebase/firestore";

function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);

  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);

  const handleBackClick = () => {
    navigate("/");
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleCloseErrorSnackbar = () => {
    setOpenErrorSnackbar(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const q = query(
      collection(db, "Usuarios"),
      where("email", "==", email),
      where("password", "==", password)
    );
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const userData = querySnapshot.docs[0].data();
      localStorage.setItem("userName", userData.name);
      localStorage.setItem("userRole", userData.role); 
      setSuccessMessage(`Bienvenido ${userData.name}`);
      setOpenSnackbar(true);
      setTimeout(() => {
        if (userData.role === "admin") {
          navigate("/admin-panel");
        } else {
          navigate("/directory");
        }
      }, 2000);
    } else {
      setErrorMessage("Correo o contraseña incorrectos");
      setOpenErrorSnackbar(true);
    }
  };

  return (
    <div className="login-page">
      <div className="login-back-button-container">
        <IconButton onClick={handleBackClick} data-aos="fade-up">
          <ArrowBack />
        </IconButton>
      </div>
      <div className="login-container" data-aos="fade-up">
        <div className="login-image" data-aos="fade-right"></div>
        <div className="login-form" data-aos="fade-left">
          <h2>Ingresar</h2>
          <form onSubmit={handleSubmit}>
            <div className="login-form-group" data-aos="fade-up" data-aos-delay="100">
              <input
                type="email"
                id="email"
                placeholder="Correo electrónico"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Email className="icon" />
            </div>
            <div className="login-form-group" data-aos="fade-up" data-aos-delay="200">
              <input
                type="password"
                id="password"
                placeholder="Contraseña"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <Lock className="icon" />
            </div>
            <button type="submit" data-aos="fade-up" data-aos-delay="300">
              Ingresar
            </button>
            <div className="login-form-links" data-aos="fade-up" data-aos-delay="400">
              <a href="/forgotPassword">¿Olvidaste tu contraseña?</a>
            </div>
          </form>
        </div>
      </div>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          sx={{ width: "100%", backgroundColor: "#4CAF50" }}
        >
          {successMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={openErrorSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseErrorSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseErrorSnackbar}
          severity="error"
          sx={{ width: "100%", backgroundColor: "#F44336" }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default Login;
