import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Css/Home.css";
import logo from "./../src/Images/SIRAX_logo.png";
import AOS from "aos";
import "aos/dist/aos.css";

function Home() {
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init({
      duration: 2000,
      easing: "ease-in-out",
      once: true,
    });
  }, []);

  const handleLoginClick = () => {
    navigate("/login");
  };

  return (
    <div className="home-container">
      <div className="header">
        <div className="header-right" data-aos="zoom-in">
          <span className="header-link" onClick={handleLoginClick}>
            Acceder
          </span>
        </div>
      </div>
      <div className="home-main-content">
        <div className="logo-container">
          <img src={logo} alt="Logo" className="logo" data-aos="fade-down" />
        </div>
        <p className="sistema-title" data-aos="zoom-in-up">
          SIRAX
        </p>
        <hr className="separator" data-aos="zoom-in-up" />
        <p className="sistema-text" data-aos="zoom-in-up">
          SISTEMA INTEGRAL DE REGISTRO DE ACCIONES DE XALISCO
        </p>
      </div>
    </div>
  );
}

export default Home;
