import React, { useState } from "react";

function EditableField({
  value,
  onSave,
  editable,
  maxLimit,
  defaultText,
  isText,
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [currentValue, setCurrentValue] = useState(value);

  const handleSave = () => {
    onSave(currentValue);
    setIsEditing(false);
  };

  const handleInput = (e) => {
    const value = e.target.value;
    if (isText) {
      setCurrentValue(value);
    } else {
      if (
        /^\d*$/.test(value) &&
        (value === "" || (Number(value) >= 0 && Number(value) <= maxLimit))
      ) {
        setCurrentValue(value);
      }
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSave();
    }
  };

  if (!editable) {
    return <span>{value || defaultText}</span>;
  }

  return isEditing ? (
    <div>
      <input
        type="text"
        value={currentValue}
        onChange={handleInput}
        onBlur={handleSave}
        onKeyPress={handleKeyPress}
        autoFocus
        inputMode={isText ? "text" : "numeric"}
      />
    </div>
  ) : (
    <div onClick={() => setIsEditing(true)}>
      <span>{value || "Clic para agregar avance"}</span>
    </div>
  );
}

export default EditableField;
